<williams-ui-platform-window
  [width]="width"
  [height]="height"
  [top]="top"
  [resizable]="false"
  [draggable]="false"
  [keepContent]="false"
  state="default"
  >
  <williams-ui-platform-window-titlebar>
    <ng-template #titlebarTemplate><ng-content select="[header]"></ng-content></ng-template>
  </williams-ui-platform-window-titlebar>
  <ng-content select="[content]"></ng-content>
  <ng-content select="[footer]"></ng-content>
</williams-ui-platform-window>
