<div [ngClass]="{ 'show-errors': showErrors }">
<kendo-combobox
  #combobox
  [class]="{ 'hide-arrow-button': hideArrowButton }"
  [data]="data"
  [clearButton]="clearButton"
  [disabled]="disabled"
  [filterable]="filterable"
  [textField]="textField"
  [valueField]="valueField"
  [value]="value"
  [valuePrimitive]="valuePrimitive"
  [size]="size"
  [fillMode]="fillMode"
  [rounded]="rounded"
  [popupSettings]="popupSettings"
  (filterChange)="onFilterChange($event)"
  (valueChange)="onValueChange($event)"
  (blur)="onBlur()"
>
</kendo-combobox>
</div>
