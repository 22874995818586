<div
  [formGroup]="group"
  class="edit-cell-wrapper"
>
  <williams-ui-platform-text-input
    *ngIf="columnConfiguration.editor == 'text'"
    type="text"
    [formGroup]="group"
    [formControlName]="columnConfiguration.formControlName"
    [clearButton]="true"
    showErrorIcon="initial"
    [maxlength]="columnConfiguration.maxLength"
    size="small"
  ></williams-ui-platform-text-input>
  <williams-ui-platform-numeric-text
  *ngIf="columnConfiguration.editor == 'numeric'"
  [formGroup]="group"
  [formControlName]="columnConfiguration.formControlName"
  [size]="'small'"
  [decimals]="columnConfiguration.numericEditorSettings.decimal"
  [maxLength]="columnConfiguration.maxLength"
  ondragstart="return false;" 
  ondrop="return false;"
  ></williams-ui-platform-numeric-text>
  <williams-ui-platform-dropdown
  #dropdown
    *ngIf="columnConfiguration.editor == 'dropdown'"
    [data]="columnConfiguration.dropdownEditorSettings.data"
    [textField]="columnConfiguration.dropdownEditorSettings.textField"
    [valueField]="columnConfiguration.dropdownEditorSettings.valueField"
    [valuePrimitive]="columnConfiguration.dropdownEditorSettings.valuePrimitive"
    [defaultItem]="columnConfiguration.dropdownEditorSettings.defaultItem"
    [formControlName]="columnConfiguration.formControlName"
    [formGroup]="group"
    size="small"
  ></williams-ui-platform-dropdown>
</div>