<williams-ui-platform-stack-layout *ngIf="errorEntity" [themeColor]="isError ? 'base error-wrapper' : 'base notify-wrapper'" [align]="{ horizontal: 'start' }" orientation="vertical">
    <p class="label" *ngIf="errorEntity.title" [innerHTML]="errorEntity.title | safeHtml"></p>
    <ul *ngIf="errorEntity.errors.length > 0" class="error-list w-m-0" [ngClass]="{'w-pl-0': errorEntity.title.length == 0, 'w-pl-7':errorEntity.title.length > 0}" >
        <li class="list-item" *ngFor="let item of errorEntity.errors" [ngClass]="{'removeListType': errorEntity.title.length == 0}">
            {{ item.message }}
        </li>
    </ul>
</williams-ui-platform-stack-layout>


