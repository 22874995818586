import { Component, EventEmitter, Output, Input, TemplateRef, TrackByFunction, ViewChild, forwardRef, ContentChild } from '@angular/core';

import { of, Observable } from 'rxjs';
import {
    CheckedState, FilterState, NodeClickEvent, TreeItem, TreeItemAddRemoveArgs,
    TreeItemChildren, TreeItemDragEvent, TreeItemDragStartEvent, TreeItemDropEvent, TreeItemLookup, TreeViewSize
} from './model';
import {TreeViewComponent as KendoTreeViewComponent} from "@progress/kendo-angular-treeview";
import { TreeViewTemplateDirective } from './directive/tree-view-template.directive';


@Component({
    selector: 'williams-ui-platform-tree-view',
    templateUrl: "./tree-view.component.html"
})

export class TreeViewComponent {
    @ContentChild(
        forwardRef(() => TreeViewTemplateDirective)
      ) 
    treeViewTemplateDirective!: any;
    /**
     * The field name which holds the data items of the child component.
     */
    @Input() childrenField!: string;
    /**
     * A function which provides the child nodes for a given parent node
     */
    @Input() children!: (item: object) => Observable<object[]>;
    /**
     * A function which determines if a specific node is checked 
     */
    @Input() hasChildren!: (item: object) => boolean;
    /**
     * A function which determines if a specific node is checked
     */
    @Input() isChecked!: (item: object, index: string) => CheckedState;
    /**
     * A function which determines if a specific node is selected
     */
    @Input() isSelected!: (item: object, index: string) => boolean;
    /**
     * A callback which determines whether a TreeView node should be rendered as hidden. 
     * The utility .k-hidden class is used to hide the nodes. Useful for custom filtering implementations.
     */
    @Input() isVisible!: (item: object, index: string) => boolean;
    /**
     * Indicates whether the child nodes will be fetched on node expand or will be initially prefetched.
     * default true
     */
    @Input() loadOnDemand!: boolean;
    /**
     * Determines whether the TreeView keyboard navigable is enabled.
     */
    @Input() navigable!: boolean;
    /**
     * Sets the size of the component.
     * The possible values are:
     * small,medium(default),large,none
     */
    @Input() size: TreeViewSize = 'large';
    /**
     * The fields of the data item that provide the text content of the nodes. 
     * If the textField input is set to an array, 
     * each hierarchical level uses the field that corresponds to the same index in the array, 
     * or the last item in the array.
     */
    @Input() textField: string | string[] = 'textField';
    /**
     * A function that defines how to track node changes. 
     * By default, the TreeView tracks the nodes by data item object reference.
     */
    @Input() trackBy!: TrackByFunction<object>
    /**
     * The nodes which will be displayed by the TreeView 
     * type any[]
     */
    @Input() nodes: any[] = [];
    /**
     *  Determines whether the content animation is enabled.
     */
    @Input() animate!: boolean;
    /**
     * Indicates whether only parent nodes should be disabled or their child nodes as well
     */
    @Input() disableParentNodesOnly!: boolean;
    /**
     *  Determines whether to allow expanding disabled nodes.
     */
    @Input() expandDisabledNodes!: boolean;
    /**
     * Sets an initial value of the built-in input element used for filtering
     */
    @Input() filter: string="";
    /**
     * Renders the built-in input element for filtering the TreeView. 
     * If set to true, the component emits the filterChange event, which can be used to filter the TreeView manually. 
     * A built-in filtering implementation is available to use with the kendoTreeViewHierarchyBinding
     * and kendoTreeViewFlatDataBinding directives. 
     */
    @Input() filterable: boolean = true;
    /**
     * The hint which is displayed when the component is empty.
     */
    @Input() filterInputPlaceholder!: string;
    @Input() treeNodeTemplate!: TemplateRef<any>;
    @Input() expandedKeys: string[] = [];
    @Input() selectedKeys: string[] = [];
    @Input() classList: string = '';
    /**
     * Fires after a dragged item is dropped 
     * Called on the TreeView where the item is dropped.
     */
    @Output() addItem: EventEmitter<TreeItemAddRemoveArgs> = new EventEmitter<TreeItemAddRemoveArgs>();
    /**
     * Fires when the user selects a TreeView node checkbox
     */
    @Output() checkedChange: EventEmitter<TreeItemLookup> = new EventEmitter<TreeItemLookup>();
    /**
     * Fires when the children of the expanded node are loaded.
     */
    @Output() childrenLoaded: EventEmitter<TreeItemChildren> = new EventEmitter<TreeItemChildren>();
    /**
     * Fires when the user collapses a TreeView node.
     */
    @Output() collapse: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();
    /**
     * Fires when the user expands a TreeView node.
     */
    @Output() expand: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();
    /**
     * Fires when the value of the built-in filter input element changes.
     */
    @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
    /**
     * Emits when the built-in filtering mechanism in the data-binding directives updates the node's visibility. 
     * Used for the built-in auto-expand functionalities of the component and available for custom implementations.
     */
    @Output() filterStateChange: EventEmitter<FilterState> = new EventEmitter<FilterState>();
    /**
     * Fires when the user clicks a TreeView node.
     */
    @Output() nodeClick = new EventEmitter<NodeClickEvent>();
    /**
     * Fires when the user double clicks a TreeView node.
     */
    @Output() nodeDblClick:EventEmitter<NodeClickEvent> = new EventEmitter<NodeClickEvent> ();
    /**
     * Fires when an item is being dragged
     */
    @Output() nodeDrag: EventEmitter<TreeItemDragEvent> = new  EventEmitter<TreeItemDragEvent> ();
    /**
     * Fires on the source TreeView after the dragged item has been dropped
     */
    @Output() nodeDragEnd: EventEmitter<TreeItemDragEvent> = new EventEmitter<TreeItemDragEvent>();
    /**
     * Fires just before the dragging of the node starts. This event is preventable.
     *  If you prevent the event default, 
     * no drag hint will be created and the subsequent drag-related events will not be fired.
     */
    @Output() nodeDragStart : EventEmitter<TreeItemDragStartEvent> = new EventEmitter<TreeItemDragStartEvent>();
    /**
     * Fires on the target TreeView when a dragged item is dropped (see example). 
     * This event is preventable. If you prevent the event default (event.preventDefualt()) 
     * or invalidate its state (event.setValid(false)),
     * the addItem and removeItem events will not be triggered.
     *  Both operations cancel the default drop operation,
     *  but the indication to the user is different. 
     * event.setValid(false) indicates that the operation was unsuccessful by animating the drag clue to its original position.
     *  event.preventDefault() simply removes the clue, as if it has been dropped successfully.
     *  As a general rule, use preventDefault to manually handle the add and remove operations,
     *  and setValid(false) to indicate the operation was unsuccessful.
     */
    @Output() nodeDrop : EventEmitter<TreeItemDropEvent> = new EventEmitter<TreeItemDropEvent>();
    /**
     * Fires when the user blurs the component.
     */
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    /**
     * Fires when the user focuses the component.
     */
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    /**
     * Fires after a dragged item is dropped. 
     * Called on the TreeView from where the item is dragged.
     */
    removeItem: EventEmitter<TreeItemAddRemoveArgs> = new EventEmitter<TreeItemAddRemoveArgs>();
    /**
     * Fires when the user selects a TreeView node 
     */
    selectionChange: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();
    @ViewChild('kendoTreeViewInstance') kendoTreeViewInstance!: KendoTreeViewComponent;

    constructor() {}
    public addItemHandler(event: TreeItemAddRemoveArgs) {
        this.addItem.emit(event);
    }

    public checkedChangeHandler(event: TreeItemLookup) {
        this.checkedChange.emit(event);
    }
    public onClickHandler(event: NodeClickEvent) {
        this.nodeClick.emit(event);
    }
    /**
     * Methods 
     * Blurs the focused TreeView item.
     */
    public blured():void{
        this.kendoTreeViewInstance.blur();
    }
    /**
     * Triggers the collapse event for the provided node.
     */
    public collapseNode(item:any, index : string): void{
        this.kendoTreeViewInstance.collapseNode(item,index);
    }
    /**
     * Triggers the expand event for the provided node and displays it's loading indicator.
     */
    public expandNode(item: any, index: string){
        this.kendoTreeViewInstance.expandNode(item, index);
    }
    /**
     * Focuses the first focusable item in the TreeView component if no hierarchical index is provided.
     */
    public focused(index?:  string){
       this.kendoTreeViewInstance.focus(index);
    }
    /**
     * Gets the current page size of the checked data item children collection
     * Since the root nodes collection is not associated with any parent data item, 
     * pass null as dataItem param to get its page size.
     * @dataItem The parent data item of the targeted collection.
     * @returns:number The page size of the checked data item children collection.
     */
    getNodePageSize(dataItem:any):number {
       return this.kendoTreeViewInstance.getNodePageSize(dataItem);
    }
    /**
     * Based on the specified index, returns the TreeItemLookup node.
     * @index The index of the node.
     * @Return: The item that was searched (looked up).
     * 
     */
    itemLookup(index:string): TreeItemLookup {
       return this.kendoTreeViewInstance.itemLookup(index);
    }
    /**
     * Triggers the children function for every expanded node,
     *  causing all rendered child nodes to be fetched again.
     */
    rebindChildren(): void {
        this.kendoTreeViewInstance.rebindChildren();
    }
    /**
     * Sets the page size of the targeted data item children collection
     * Since the root nodes collection is not associated with any parent data item, 
     * pass null as dataItem param to target its page size.
     */
    setNodePageSize(dataItem:any, pageSize:number):void{
        this.kendoTreeViewInstance.setNodePageSize(dataItem,pageSize);
    }

}

