import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonFillMode, ButtonRounded, ButtonSize, ButtonThemeColor } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'williams-ui-platform-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() mantIconClassName:string = "";
  @Input() fillMode: ButtonFillMode = 'solid';
  @Input() icon!: string;
  @Input() matIcon!: string;
  @Input() iconClass!: string;
  @Input() imageUrl!: string;
  @Input() rounded: ButtonRounded = 'none';
  @Input() selected = false;
  @Input() toggleable = false;
  @Input() classList: string = '';
  @Input() type: string = 'button';
  @Input() themeColor:ButtonThemeColor = 'base';
  @Input() size: ButtonSize = 'medium';
  @Input() width = 'auto';
  @Output() clicked = new EventEmitter<any>();
  @Output() selectedChange = new EventEmitter<any>();
  @Input() elementId!: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  onClick(event: Event) {
    this.clicked.emit(event);
  }
  onSelectedChange(event: any) {
    this.selectedChange.emit(event);
  }

}
