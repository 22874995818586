<kendo-numerictextbox
 [spinners]="false"
 [formControl]="control"
 [decimals]="decimals"
 [format]="format"
 [value]="value"
 [fillMode]="fillMode"
 [size]="size"
 [rounded]="rounded"
 [maxlength]="maxLength"
>
</kendo-numerictextbox>