<kendo-splitbutton
  [data]="data"
  buttonClass="main-btn"
  [arrowButtonIcon]="arrowButtonIcon"
  [icon]="icon"
  [imageUrl]="imageUrl"
  [text]="text"
  [type]="type"
  [size]="size"
  [fillMode]="fillMode"
  [rounded]="rounded"
  [themeColor]="themeColor"
  [disabled]="disabled"
  (buttonClick)="btnClick.emit()"
>
<mat-icon  *ngIf="matIcon" [fontIcon]="matIcon"></mat-icon>
<ng-content></ng-content>
<ng-template kendoSplitButtonItemTemplate let-dataItem *ngIf="splitButtonTemplateDirective?.iconTemplate">
  <ng-container   *ngTemplateOutlet="splitButtonTemplateDirective.iconTemplate;context: { dataItem }">
  </ng-container>
</ng-template>
</kendo-splitbutton>
