import { AfterViewInit, Component, ContentChild, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterableSettings, GridComponent, GridDataResult, GroupableSettings, SelectableSettings } from '@progress/kendo-angular-grid';
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { AggregateDescriptor as KendoAggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor as KendoGroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { GridHeaderActionsDirective, numberFormatter, RowClassCallback } from '@williams-ui-platform/w-ui-angular';
import { merge, Subscription } from 'rxjs';
import { GridColumnDef } from '../models/grid-column-def';

export interface GridValidationResult {
  valid: boolean;
  errors: { [key: string]: any }[]
}

export interface ValueChangeEvent {
  validation: GridValidationResult
}

interface GroupDescriptor extends KendoGroupDescriptor {}

interface AggregateDescriptor extends KendoAggregateDescriptor {}
@Component({
  selector: 'williams-ui-platform-editable-grid',
  templateUrl: './editable-grid.component.html',
  styleUrls: ['./editable-grid.component.scss']
})
export class EditableGridComponent implements AfterViewInit, OnChanges {
  private counter = 0;
  @ViewChild('grid', { static: false }) grid!: GridComponent;
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  @ContentChild(forwardRef(()=>GridHeaderActionsDirective)) gridHeaderAction!: any;
  // @ContentChild(GridHeaderActionsDirective) gridHeaderAction: any;
  @Input('gridData') set gridData(data: any[]) {
    this._gridData = data.map(value => ({
      ...value,
      rowId: this.counter++,
      isNewRecord: false
    }));
    this.rowsInEditMode = new Map();
  };
  @Input('gridColumnDefs') set columnDefs(data: GridColumnDef[]) {
    this.gridColumnDefs = data.map(item => {
      return {
        ...item,
        editConfig: item.editConfig ?? {
          add: item.editable,
          edit: item.editable
        },
        formControlName: item.formControlName ?? item.field
      }
    })
  };
  @Input() createFormGroupFunction: any;
  @Input() height!: number;
  @Input() showtableTitle = false;
  @Input() tableTitle: string = '';
  @Input() showBulkDelete = true;
  @Input() showBulkDuplicate = true;
  @Input() enableRowAddition = true;
  @Input() enableSelection = true;
  @Input() showCommandColumn = true;
  @Input() filterable: FilterableSettings = 'menu';
  @Input() sortable = true;
  @Input() resizable = true;

  @Input() showAggregate = true;
  @Input() rowCountLable: string = 'Row';
  @Input() hightlightRowsWithError = false;

  @Input() rowClassCallbackFn!: RowClassCallback;
  @Input() columnClassCallbackFn!: (dataItem: any, field: string) => string | string[] | { [key: string]: any } | Set<string>;
  @Input() addCallbackFn!: Function;
  @Input() emptyRecordsMessage = 'No records available.'

  @Input() reorderable = true;
  // Inputs related to Grid Grouping
  @Input() groupable: GroupableSettings | boolean = false;
  @Input() groups: GroupDescriptor[] = [];
  @Input() groupAggregates: AggregateDescriptor[] = [];
  @Input() readonly = false;

  @Output() valueChange = new EventEmitter<ValueChangeEvent>();

  gridColumnDefs: GridColumnDef[] = [];
  _gridData!: any[];

  selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple'
  }

  splitButtonData = [{
    text: 'Add 5 items',
    click: () => {
      this.addRows(5);
    }
  }, {
    text: 'Add 10 items',
    click: () => {
      this.addRows(10);
    }
  }]

  gridRowCommands = [{
    text: 'Copy',
    action: 'duplicate'
  }];

  selectedRows: number[] = [];

  gridView!: GridDataResult;
  filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  sort: SortDescriptor[] = []

  rowsInEditMode: Map<number, FormGroup> = new Map();
  private _subscription!: Subscription;

  constructor() {
    this.rowClassCallback = this.rowClassCallback.bind(this);
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.editAllRows(); }, 100);
    // this.handleFormValueChange().subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gridData']) {
      this.loadGridView();
      if(!changes['gridData'].isFirstChange()) {
        this.resetGrid();
        this.editAllRows();
      }
    }
  }

  loadGridView() {
    const group = this.groups.map(group => ({ ...group, aggregates: this.groupAggregates }))
    this.gridView = process(this._gridData, { sort: this.sort, filter: this.filter, group })
  }

  handleFormValueChange(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    const groups = this._gridData.map(item => this.rowsInEditMode.get(item.rowId) as FormGroup);
    this._subscription = merge(...groups.map(item => item.valueChanges)).subscribe(() => {
      const payload = {
        validation: this.validate(groups)
      }
      this.valueChange.emit(payload);
    });
  }

  getGridDataList(): any[] {
    let data: any[] = [];
    if(this.groupable && this.groups.length > 0) {
      data = this.flattenGridDataArray(this.gridView.data);
    } else {
      data = this.gridView.data;
    }
    return data;
  }

  flattenGridDataArray(group: any[]): any[] {
    const output = [];
    for(let groupItem of group) {
      if(groupItem['field']) {
        output.push(...this.flattenGridDataArray(groupItem.items));
      } else {
        output.push(groupItem);
      }
    }
    return output;
  }
  
  private closeAllRows(): void {
    const data = this.getGridDataList();
    data.forEach((dataItem, index) => {
      this.grid.closeRow(index);
    })
  }

  private resetGrid(): void {
    this.closeAllRows();
  }

  editAllRows() {
    if(this.readonly) {
      return;
    }
    
    const data = this.getGridDataList();
    data.forEach((dataItem, index) => {
      this.grid.closeRow(index);
      const group = this.rowsInEditMode.get(dataItem.rowId);
      if (group) {
        this.grid.editRow(index, group);
      } else {
        const group = this.createFormGroupFunction(dataItem) as FormGroup;
        this.rowsInEditMode.set(dataItem.rowId, group);
        this.grid.editRow(index, group);
      }
    });
    this.handleFormValueChange();
  }

  addRows(count: number = 1) {
    const items = Array(count).fill({}).map(() => ({ ...this.addCallbackFn(), rowId: this.counter++, isNewRecord: true }));
    this._gridData.unshift(...items);
    this.loadGridView();
    this.editAllRows();
  }

  onCommandItemClick({ action }: { action: 'duplicate' }, dataItem: any): void {
    switch (action) {
      case 'duplicate': this.duplicateRow(dataItem);
        break;
    }
  }

  duplicateRow(dataItem: any) {
    const item = this.mergeEditedDataWithOriginalData(
      dataItem.rowId,
      this.rowsInEditMode.get(dataItem.rowId)?.value
    );
    this._gridData.unshift({ ...item, rowId: this.counter++, isNewRecord: true });
    this.loadGridView();
    this.editAllRows();
  }

  deleteRow(dataItem: any,event: Event) {
    event?.stopPropagation();
    const index = this._gridData.findIndex(item => item.rowId === dataItem.rowId);
    this._gridData.splice(index, 1);
    this.loadGridView();
    this.rowsInEditMode.delete(dataItem.rowId);
    this.editAllRows();
    this.removeFromSelectedRowList(dataItem.rowId);
    this.handleValidationOnDelete();

  }

  removeFromSelectedRowList(rowId: number) {
    const index = this.selectedRows.findIndex(item => item === rowId);
    this.selectedRows.splice(index, 1);
  }

  deleteSelectedRows(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    this.selectedRows.forEach(rowId => {
      const index = this._gridData.findIndex(dataItem => dataItem.rowId === rowId);
      this._gridData.splice(index, 1);
      this.loadGridView();
      this.rowsInEditMode.delete(rowId);
      this.editAllRows();
    });
    this.selectedRows = [];
    this.handleValidationOnDelete();
  }

  duplicateSelectedRows(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    const itemsToAppend = this.selectedRows.map(rowId => {
      const dataItem = this.mergeEditedDataWithOriginalData(
        rowId,
        this.rowsInEditMode.get(rowId)?.value
      );
      return { ...dataItem, rowId: this.counter++, isNewRecord: true };
    });
    this._gridData.unshift(...itemsToAppend);
    this.loadGridView();
    this.selectedRows = [];
    this.editAllRows();
  }


  createFormGroup(dataItem: any): FormGroup {
    const group = this.createFormGroupFunction(dataItem) as FormGroup;
    group.addControl('rowId', new FormControl(dataItem.rowId));
    return group;
  }

  getColumnAggregate(def: GridColumnDef): string {
    const label = def.aggregate?.label ?? 'total' + def.title
    if (def.aggregate?.total) {
      return `${def.aggregate?.total} ${label}}`;
    }
    let values: any[] = [];
    if(this.readonly) {
      values = this.gridView.data;
    } else {
      values = this.gridView.data.map(dataItem => this.rowsInEditMode.get(dataItem.rowId)?.value ?? {})
    }
    const total = values.reduce((sum, value) => {
      let fieldValue = +value[def.field];
      if (!fieldValue || isNaN(fieldValue)) {
        fieldValue = 0
      }
      return sum + fieldValue;
    }, 0);

    return `${total} ${label}`;
  }

  /**
 * Will return the lates value of the grid
 */
  getUpdatedGridData() {
    // Merging original data with data in form group value
    const data = this._gridData.map((dataItem) => ({
      ...dataItem,
      ...this.rowsInEditMode.get(dataItem.rowId)?.value,
    }));
    return {
      valid: this.validate(),
      data
    }
  }

  validate(formGroups?: FormGroup[]): GridValidationResult {
    if(this.readonly) {
      return {
        valid: true,
        errors: []
      }
    }
    if (!formGroups) {
      formGroups = this._gridData.map(dataItem => this.rowsInEditMode.get(dataItem.rowId) as FormGroup);
    }
    const isValid = formGroups.every(group => group.valid);
    return {
      valid: isValid,
      errors: isValid ? [] : this.mapFormErrors(formGroups)
    }
  }

  getEditableFieldList(): string[] {
    return this.gridColumnDefs.filter(def => def.editable).map(def => def.formControlName ?? def.field);
  }

  mapFormErrors(controls: FormGroup[]): { [key: string]: any }[] {
    const fields = this.getEditableFieldList();

    return controls.map(group => fields.reduce((result, field) => {
      return {
        ...result,
        [field]: group.get(field)?.errors
      }
    }, {}))
  }

  markFieldsAsTouched(): void {
    this._gridData.forEach(dataItem => {
      const group = this.rowsInEditMode.get(dataItem.rowId);
      group?.markAllAsTouched();
    })
  }

  rowClassCallback(context: { dataItem: any }): { [key: string]: any } {
    let errors = {};
    if(this.hightlightRowsWithError) {
      const hasError = !!this.rowsInEditMode.get(context.dataItem.rowId)?.invalid;
      errors = {
        'row-error': hasError
      }
    }
    if (this.rowClassCallbackFn){
      errors = { ...errors, ...this.rowClassCallbackFn(context) };
    }
    return errors;
  }

  columnClassCallback(dataItem: any, field: string): string | string[] | { [key: string]: any } | Set<string> {
    if (this.columnClassCallbackFn) {
      return this.columnClassCallbackFn(dataItem, field);
    }
    return '';
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadGridView();
    this.editAllRows();
  }

  sortChange(event: SortDescriptor[]) {
    this.sort = event;
    this.loadGridView();
    this.editAllRows();
  }

  groupChange(groups: GroupDescriptor[]) {
    this.groups = groups;
    this.loadGridView();
    this.editAllRows();
  }

  mergeEditedDataWithOriginalData(rowId: number, editValue: any): any {
    const originalRowData = this._gridData.find(item => item.rowId === rowId);
    if(!originalRowData) {
      return editValue;
    }
    return {
      ...originalRowData,
      ...editValue
    }
  }

  getColumnValue(dataItem: any, field: string): string {
    const fieldArr = field.split('.');
    return fieldArr.reduce((result, key) => {
      return result[key]
    }, { ...dataItem });
  }

  handleValidationOnDelete(): void {
    const groups = this._gridData.map(item => this.rowsInEditMode.get(item.rowId) as FormGroup);
    const payload = {
      validation: this.validate(groups)
    }
    this.valueChange.emit(payload);
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.classList.contains('k-column-title') ||
      element.hasAttribute('show-tooltip')) &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  isColumnFilterable(columnDef: GridColumnDef): boolean {
    if(columnDef.filterable === false) {
      return false;
    }

    return !columnDef.editable;
  }

  isColumnSortable(columnDef: GridColumnDef): boolean {
    if(columnDef.sortable === false) {
      return false;
    }

    return !columnDef.editable;
  }

  calculateGroupAggregateForEditableColumn(group: { items: any[] }, columnDef: GridColumnDef): number {
    const items = this.flattenGridDataArray(group.items)
    return this.getAggregateForEditableColumn(items, columnDef);
  }

  hasAggregate(field: string) {
    return this.groups.some(group => {
      return group.field === field && group.aggregates && group.aggregates.length > 0;
    })
  }

  getAggregateForColumn(columnDef: GridColumnDef, column: any) {
    const items = this.getGridDataList();
    let aggregatedVal;
    if(!column.editable || this.readonly) {
      aggregatedVal =  items.reduce((result: number, item: any) => {
        return result += +this.getColumnValue(item, columnDef.field);
      }, 0);
    } else {
      aggregatedVal = this.getAggregateForEditableColumn(items, columnDef);
    }
    return `${columnDef?.aggregate?.label} ${this.getFormattedValue(aggregatedVal,columnDef)}`
  }

  getAggregateForEditableColumn(items: any[], columnDef: GridColumnDef) {
    return items.reduce((result: number, item: any) => {
      const formGroup = this.rowsInEditMode.get(item.rowId);
      if(formGroup) {
        const formControlName = columnDef.formControlName ?? columnDef.field;
        result += +formGroup.get(formControlName)?.value;
      }
      return result;
    }, 0)
  }

  private _flattenGroupedGridViewData(gridViewData: any[]): any[] {
    const result = []
    for(let item of gridViewData) {
      if(item.items) {
        result.push(...this._flattenGroupedGridViewData(item.items));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  getAggregateValue(field: string, columnDef: GridColumnDef[]) {
    const column = columnDef?.find(column => column.field === field);
    const formControlName = column?.formControlName;
    const data = this._flattenGroupedGridViewData(this.gridView.data);
    const total = data.reduce((tot, item) => {
      const formGroup = this.rowsInEditMode.get(item.rowId);
      let value = 0;
      if (formGroup && formControlName) {
        value = +formGroup.get(formControlName)?.value;
      } else {
        value = item[field];
      }
      return tot + value;
    }, 0);

    if (column?.numberFormat?.required) {
      return numberFormatter(total, column.numberFormat?.locale);
    }
    return total;
  }

  getRowCount() {
    return this.gridView.total;
  }

  getFormattedValueForFields(dataItem: any, columnDef: any) {
    if (columnDef.numberFormat?.required) {
      return numberFormatter(
        dataItem[columnDef.field],
        columnDef.numberFormat?.locale
      );
    }
    return dataItem[columnDef.field];
  }

  getFormattedValue(val: any, aggregateDef: any) {
    if (aggregateDef?.numberFormat?.required) {
      return numberFormatter(val,aggregateDef.numberFormat?.locale);
    }
    return val;
  }

  isValidRow(rowId: number): boolean {
    const group = this.rowsInEditMode.get(rowId);
    if(!group) return true;
    return group.valid;
  }

  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}