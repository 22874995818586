<ng-container *ngIf="!elementId; else focussedbutton">
<div *ngIf="control ; else elseBlock">
  <input
  kendoCheckBox
  [formControl]="control"
  type="checkbox"
  [size]="size"
  [rounded]="rounded"
  [checked]="checked"
  (click)="onClick()"
  [disabled]="disabled"
/>
</div>
<ng-template #elseBlock>
  <input
  kendoCheckBox
  type="checkbox"
  [size]="size"
  [rounded]="rounded"
  [checked]="checked"
  (click)="onClick()"
  [disabled]="disabled"
  />
</ng-template>
</ng-container>

<ng-template #focussedbutton>
  <div *ngIf="control ; else elseBlock">
    <input
    kendoCheckBox
    [formControl]="control"
    type="checkbox"
    [size]="size"
    [rounded]="rounded"
    [checked]="checked"
    (click)="onClick()"
    [id]="elementId"
    [disabled]="disabled"
  />
  </div>
  <ng-template #elseBlock>
    <input
    kendoCheckBox
    type="checkbox"
    [size]="size"
    [rounded]="rounded"
    [checked]="checked"
    (click)="onClick()"
    [id]="elementId"
    [disabled]="disabled"
    />
  </ng-template>
</ng-template>