import { Component, forwardRef, Input, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DropDownFillMode, DropDownRounded, DropDownSize, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { BaseAccessor } from '../base/base-accessor';

export interface DropdownOption {
  label: string;
  value: string | null;
}

export type DropdownOptions = DropdownOption[];

export type ItemDisabledCallback = (itemArgs: { dataItem: any; index: number }) => boolean;

@Component({
  selector: 'williams-ui-platform-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent extends BaseAccessor implements OnInit {
  @Input() data: DropdownOption | any[] = [];
  @Input() valueField!: string;
  @Input() textField!: string;
  @Input() value!: any;
  @Input() valuePrimitive = true;
  @Input() placeholder: string = "Select";
  @Input() size: DropDownSize = "medium";
  @Input() popupSettings: PopupSettings = {
    width: 200
  };
  @Input() defaultItem!: DropdownOption | any;
  @Input() showErrors = false;
  @Input() showErrorMsg = false;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'none';
  @Input() readonly = false;
  @Input() disabled = false;


  @Input() itemDisabledCallback!: ItemDisabledCallback; 
  @Output() selectionChange = new EventEmitter<any>();
 

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
    this.itemDisabled = this.itemDisabled.bind(this);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.valuePrimitive && this.control) {
      this.setCustomRequiredValidator();
    }
  }
 
  onSelectionChange(value:any) {
    this.selectionChange.emit({name:this.formControlName,value:value});
  }
  /**
   * If the control has requred built-in validator, the function removes it  adds a custom required
   * validator which returns similar error object { required: true } in case of error
   * 
   * Adding this because when valuePrimitive is set to false, the required built-in validator does not support object validation 
   * Reference - https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/forms/#toc-reactive-forms
   */
  setCustomRequiredValidator(): void {
    const control = this.control as AbstractControl;
    if (!control.hasValidator(Validators.required)) {
      return;
    }

    control.removeValidators(Validators.required);
    control.addValidators((control: AbstractControl) => {
      if (!control.value) {
        return { required: true };
      }
      return control.value[this.valueField] ? null : { required: true };
    });
  }

  itemDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    if (this.itemDisabledCallback) {
      return this.itemDisabledCallback(itemArgs);
    }
    return false;
  }
}
