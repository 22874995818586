import { Component, ContentChild, Directive, EventEmitter, forwardRef, Input, Output, TemplateRef } from "@angular/core";
import {
  ButtonFillMode,
  ButtonRounded,
  ButtonSize,
  ButtonThemeColor
} from "@progress/kendo-angular-buttons";

@Component({
  selector: "williams-ui-platform-splitbutton",
  templateUrl: "./splitbutton.component.html",
})
export class SplitButtonComponent {
  @Input() text!: string;
  @Input() data: any[] = [];
  @Input() icon!: string;
  @Input() type = "button";
  @Input() matIcon!: string;
  @Input() imageUrl!: string;
  @Input() arrowButtonIcon: string = "caret-alt-down";
  @Input() size: ButtonSize = "medium";
  @Input() fillMode: ButtonFillMode = "outline";
  @Input() rounded: ButtonRounded = "none";
  @Input() themeColor: ButtonThemeColor = "primary";
  @Input() disabled: boolean = false;
  @Output() btnClick: EventEmitter<void> = new EventEmitter();
  @ContentChild(forwardRef(() => SplitButtonIconTemplateDirective)) splitButtonTemplateDirective!: SplitButtonIconTemplateDirective;

}

@Directive({
  selector: 'williams-ui-platform-icon-template'
})
export class SplitButtonIconTemplateDirective {
  @ContentChild('iconTemplate') iconTemplate!: TemplateRef<any>;

  constructor() { }
}
