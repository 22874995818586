<div [ngClass]="{ 'show-errors': showErrors }">
  <kendo-dropdownlist
    class="mt-4"
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [value]="value"
    [valuePrimitive]="valuePrimitive"
    [defaultItem]="defaultItem"
    [formControl]="control"
    [itemDisabled]="itemDisabled"
    [size]="size"
    [fillMode]="fillMode"
    [rounded]="rounded"
    [disabled]="disabled"
    [readonly]="readonly"
    (selectionChange)="onSelectionChange($event)"
  >
  <ng-template  kendoDropDownListValueTemplate let-dataItem>
    <span>{{dataItem? dataItem[textField]: ''}}</span>
  <kendo-icon class="dropdownErrorIcon w-icon-color w-mt-thin" *ngIf="showErrorMsg"  name="warning"></kendo-icon>
  </ng-template>
</kendo-dropdownlist>
</div>