import { ContentChild, Directive, TemplateRef } from "@angular/core";

@Directive({
  selector: 'williams-ui-platform-grid-templates'
})
export class GridTemplatesDirective {
  @ContentChild('pdfTemplate') pdfTemplate!: TemplateRef<any>;
  @ContentChild('filterCellTemplate') filterCellTemplate!: TemplateRef<any>;
  @ContentChild('filterMenuTemplate') filterMenuTemplate!: TemplateRef<any>;
  @ContentChild('footerTemplate') footerTemplate!: TemplateRef<any>;
  @ContentChild('groupFooterTemplate') groupFooterTemplate!: TemplateRef<any>;
  @ContentChild('commandColumnTemplate') commandColumnTemplate!: TemplateRef<any>;
  @ContentChild('noRecordsTemplate') noRecordsTemplate!: TemplateRef<any>;
  @ContentChild('detailTemplate') detailTemplate!: TemplateRef<any>
}