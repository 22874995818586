// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment =
{
  production: true,
  apiUrlPrefix: '',
  commonConfigURL: '/assets/config/common-config-data.json',
  clientErrorURL: '/assets/config/client-error-data.json',
  fileLocation: 'Discovery',
  EBBName: 'Discovery Gas Transmission LLC',
  MapName: 'Discovery',
  ShortName: 'Discovery',
  ID: '281',
  shortAddress1: 'Discovery Gas Transmission LLC',
  shortAddress2: '2800 Post Oak Boulevard, Level 3,',
  shortAddress3: 'Houston, Texas 77056',
  shortAddress4: 'P.O.Box 1396, Houston, Texas 77251-1396',
  longAddress1: '2800 Post Oak Boulevard, Level 3, Houston, Texas 77056',
  longAddress2: 'P.O.Box 1396, Houston, Texas 77251-1396',
  isFlowing: 'true',
  showContacts: 'true',
  plantName: 'Larose Plant inlet',
  showHeaderImage: 'true',
  headerIcon: 'true',
  CompanyName: 'Discovery Gas Transmission LLC',
  showIndex: 'true',
  tariffFile: 'discovery_trf.pdf',
  operationallyAvailableTitle: 'Discovery Pipeline Operationally Available Capacity',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
