import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { IconModule, IconsModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListBoxModule } from "@progress/kendo-angular-listbox";
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from "@progress/kendo-angular-popup";
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ActionHeaderComponent } from './components/common/action-header/action-header.component';
import { AutocompleteComponent } from './components/common/autocomplete/autocomplete.component';
import { ButtonComponent } from './components/common/button/button.component';
import { CheckboxComponent } from './components/common/checkbox/checkbox.component';
import { ComboboxComponent } from './components/common/combobox/combobox.component';
import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm-dialog.component';
import { ContactFormComponent } from './components/common/contact-form/contact-form.component';
import { ContentDisplayComponent } from './components/common/content-display/content-display.component';
import { CriteriaFormComponent } from './components/common/criteria-form/criteria-form.component';
import { DatepickerComponent } from './components/common/datepicker/datepicker.component';
import { DialogContainerComponent } from './components/common/dialog-container/dialog-container.component';
import { DropdownComponent } from './components/common/dropdown/dropdown.component';
import { EditableGridComponent } from './components/common/editable-grid/editable-grid.component';
import { EmptyMessageComponent } from './components/common/empty-message/empty-message.component';
import { FileUploadComponent } from './components/common/file-upload/file-upload.component';
import { FormfieldComponent } from './components/common/formfield/formfield.component';
import { GridAggregate } from './components/common/grid/components/grid-aggregate/grid-aggregate.component';
import { GridCellTemplate } from './components/common/grid/components/grid-cell-template/grid-cell-template.component';
import { GridCommandColumnTemplate } from './components/common/grid/components/grid-command-column-template/grid-command-column-template.component';
import { GridEditTemplate } from './components/common/grid/components/grid-edit-template/grid-edit-template.component';
import { GridComponent } from './components/common/grid/grid.component';
import { IndicatorComponent } from './components/common/indicator/indicator.component';
import { LabelComponent } from './components/common/label/label.component';
import { ListComponent } from './components/common/list-box/list-box.component';
import { MaskedTextInputComponent } from './components/common/masked-text-input/masked-text-input.component';
import { MultiselectComponent } from './components/common/multiselect/multiselect.component';
import { NumericTextComponent } from './components/common/numeric-text/numeric-text.component';
import { SplitButtonComponent, SplitButtonIconTemplateDirective } from './components/common/splitbutton/splitbutton.component';
import { WilliamsWrapStepperComponent } from './components/common/stepper/williams-stepper.component';
import { TabListComponent } from './components/common/tab-list/tab-list.component';
import { TextAreaComponent } from './components/common/text-area/text-area.component';
import { TextInputComponent } from './components/common/text-input/text-input.component';
import { ListViewComponent } from './components/layouts/list-view/list-view.component';
import { PanelbarComponent } from "./components/layouts/panelbar/panelbar.component";
import { SideNavbarLayoutComponent } from './components/layouts/side-navbar-layout/side-navbar-layout.component';
import { TabLayoutComponent } from './components/layouts/tab-layout/tab-layout.component';
import { TreeViewComponent } from './components/layouts/tree-view/tree-view.component';
import { GridHeaderActionsDirective } from './directives/grid-header-actions.directive';
import { ListViewFooterDirective } from './directives/list-view-footer.directive';
import { ListViewHeaderDirective } from './directives/list-view-header.directive';
import { ListViewItemDirective } from './directives/list-view-item.directive';
import { PanelbarItemDirective } from "./directives/panelbar-item.directive";

import { MatIconModule } from '@angular/material/icon';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { BadgeComponent, BadgeContainerComponent } from './components/common/badge';
import { BreadCrumbComponent } from './components/common/bread-crumb/bread-crumb.component';
import { BreadCrumbTemplateDirective } from './components/common/bread-crumb/directive/bread-crumb-template.directive';
import { ButtonGroupComponent } from "./components/common/button-group/button-group.component";
import { CarouselComponent } from './components/common/carousel/carousel.component';
import { CarouselTemplateDirective } from './components/common/carousel/directive/carousel-template.directive';
import { CategoryAxisItemComponent } from './components/common/chart/axis/category-axis-item/category-axis-item.component';
import { CategoryAxisComponent } from './components/common/chart/axis/category-axis/category-axis.component';
import { ValueAxisItemComponent } from './components/common/chart/axis/value-axis-item/value-axis-item.component';
import { ValueAxisComponent } from './components/common/chart/axis/value-axis/value-axis.component';
import { ChartTitleComponent } from './components/common/chart/chart-title/chart-title.component';
import { ChartComponent } from './components/common/chart/chart.component';
import { SeriesDefaultsComponent } from './components/common/chart/series/series-defaults/series-defaults.component';
import { SeriesItemTooltipComponent } from './components/common/chart/series/series-item-tooltip/series-item-tooltip.component';
import { SeriesItemComponent } from './components/common/chart/series/series-item/series-item.component';
import { SeriesComponent } from './components/common/chart/series/series.component';
import { DigitalClockComponent } from './components/common/digital-clock/digital-clock.component';
import { DropdownButtonTemplateDirective } from './components/common/dropdown-button/directives/dropdown-button-template.directive';
import { DropdownButtonComponent } from './components/common/dropdown-button/dropdown-button.component';
import {ErrorMessagesComponent, SafeHtmlPipe} from './components/common/error-messages/error-messages.component';
import { ExtendedGridComponent, GridTemplatesDirective } from './components/common/extended-grid';
import { GridCellEditComponent } from './components/common/extended-grid/grid-cell-edit/grid-cell-edit.component';
import { GridCellComponent } from './components/common/extended-grid/grid-cell/grid-cell.component';
import { FormErrorComponent } from "./components/common/form-error/form-error.component";
import { FormMessageComponent } from "./components/common/form-message/form-message.component";
import {
  GridViewExpansionPanelComponent
} from "./components/common/grid-view-expansion-panel/grid-view-expansion-panel.component";
import { GridViewLayoutComponent } from "./components/common/grid-view-expansion-panel/grid-view-layout.component";
import { LinkButtonComponent } from './components/common/link-button/link-button.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import {
  MultiColumnCombobox,
  MultiColumnComboBoxTemplatesDirective
} from "./components/common/multi-column-combobox";
import { RadioButtonComponent } from "./components/common/radio-button/radio-button.component";
import { SearchTextComponent } from './components/common/search-text/search-text.component';
import { StepperLabelTemplateDirective } from './components/common/stepper/directive/stepper-template.directive';
import { TextInputTemplateDirective } from './components/common/text-input/directive/text-input-template.directive';
import { SidebarComponent, WindowComponent, WindowTitlebarDirective } from './components/common/window';
import {
  CardComponent,
  NoticeCardComponent
} from "./components/layouts/card";
import { DrawerComponent, DrawerTemplatesDirective } from './components/layouts/drawer';
import { ExpansionPanelComponent } from './components/layouts/expansion-panel/expansion-panel.component';
import { GridLayoutComponent, GridLayoutItemDirective } from './components/layouts/gridlayout';
import { StackLayoutComponent } from './components/layouts/stack-layout/stack-layout.component';
import { TabStripTabTemplateDirective } from './components/layouts/tab-strip/tab-strip-tab/directive/tab-strip-tab-template.directive';
import { TabStripTabComponent } from './components/layouts/tab-strip/tab-strip-tab/tab-strip-tab.component';
import { TabStripComponent } from './components/layouts/tab-strip/tab-strip.component';
import { TileLayoutComponent } from './components/layouts/tile-layout/tile-layout.component';
import { TilelayoutItemBodyComponent } from './components/layouts/tile-layout/tilelayout-item-body/tilelayout-item-body.component';
import { TilelayoutItemHeaderComponent } from './components/layouts/tile-layout/tilelayout-item-header/tilelayout-item-header.component';
import { TilelayoutItemComponent } from './components/layouts/tile-layout/tilelayout-item/tilelayout-item.component';
import { TreeViewTemplateDirective } from './components/layouts/tree-view/directive/tree-view-template.directive';
import { ExpansionPanelTitleDirective } from './directives/expansion-panel-title.directive';
import { WDatePipe } from "./pipes/w-date-pipe";
import { WPhonePipe } from "./pipes/w-phone-pipe";
import { SwitchComponent } from './components/common/switch';
import { ToolTipComponent } from './components/common/tooltip/tooltip.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    RouterModule,
    DateInputsModule,
    DropDownsModule,
    UploadsModule,
    DialogsModule,
    PopupModule,
    IconsModule,
    ExcelModule,
    PDFModule,
    NotificationModule,
    ListViewModule,
    TooltipsModule,
    ListBoxModule,
    TreeViewModule,
    TypographyModule,
    IconModule,
    SVGIconModule,
    ScrollViewModule,
    IndicatorsModule,
    NavigationModule,
    MatIconModule,
    ChartsModule
    
  ],
  declarations: [
    SideNavbarLayoutComponent,
    ContactFormComponent,
    CriteriaFormComponent,
    FileUploadComponent,
    WilliamsWrapStepperComponent,
    TabListComponent,
    EditableGridComponent,
    TextInputComponent,
    DropdownComponent,
    GridComponent,
    ActionHeaderComponent,
    FormfieldComponent,
    LabelComponent,
    MultiselectComponent,
    DatepickerComponent,
    CheckboxComponent,
    ButtonComponent,
    DialogContainerComponent,
    ListViewComponent,
    ListViewHeaderDirective,
    ListViewFooterDirective,
    ListViewItemDirective,
    GridHeaderActionsDirective,
    EmptyMessageComponent,
    TabLayoutComponent,
    IndicatorComponent,
    DrawerComponent,
    PanelbarComponent,
    PanelbarItemDirective,
    SplitButtonComponent,
    AutocompleteComponent,
    MaskedTextInputComponent,
    ComboboxComponent,
    ContentDisplayComponent,
    ConfirmDialogComponent,
    TextAreaComponent,
    ListComponent,
    NumericTextComponent,
    GridCellTemplate,
    GridEditTemplate,
    GridCommandColumnTemplate,
    GridAggregate,
    TreeViewComponent,
    MultiColumnCombobox,
    MultiColumnComboBoxTemplatesDirective,
    ExpansionPanelComponent,
    ExpansionPanelTitleDirective,
    TreeViewTemplateDirective,
    CarouselTemplateDirective,
    TextInputTemplateDirective,
    SearchTextComponent,
    DigitalClockComponent,
    CarouselComponent,
    DrawerTemplatesDirective,
    StackLayoutComponent,
    CardComponent,
    BadgeComponent,
    BadgeContainerComponent,
    NoticeCardComponent,
    BreadCrumbComponent,
    BreadCrumbTemplateDirective,
    ExtendedGridComponent,
    GridCellComponent,
    GridTemplatesDirective,
    GridCellEditComponent,
    LoaderComponent,
    TabStripComponent,
    TabStripTabComponent,
    TabStripTabTemplateDirective,
    LinkButtonComponent,
    GridLayoutComponent,
    GridLayoutItemDirective,
    TileLayoutComponent,
    TilelayoutItemComponent,
    TilelayoutItemBodyComponent,
    TilelayoutItemHeaderComponent,
    ErrorMessagesComponent,
    DropdownButtonComponent,
    SplitButtonIconTemplateDirective,
    WDatePipe, WPhonePipe,
    ButtonGroupComponent,
    RadioButtonComponent,
    FormMessageComponent,
    FormErrorComponent,
    GridViewLayoutComponent,
    GridViewExpansionPanelComponent,
    WindowComponent,
    WindowTitlebarDirective,
    SidebarComponent,
    StepperLabelTemplateDirective,
    DropdownButtonTemplateDirective,
    ChartComponent,
    CategoryAxisComponent,
    CategoryAxisItemComponent,
    SeriesComponent,
    SeriesItemComponent,
    SeriesDefaultsComponent,
    ChartTitleComponent,
    ValueAxisItemComponent,
    SeriesItemTooltipComponent,
    ValueAxisComponent,
    SwitchComponent,
    ToolTipComponent,
    SafeHtmlPipe,
    AutoFocusDirective
  ],
  exports: [
    SideNavbarLayoutComponent,
    ContactFormComponent,
    CriteriaFormComponent,
    FileUploadComponent,
    WilliamsWrapStepperComponent,
    TabListComponent,
    EditableGridComponent,
    TextInputComponent,
    DropdownComponent,
    AutocompleteComponent,
    GridComponent,
    ActionHeaderComponent,
    FormfieldComponent,
    LabelComponent,
    MultiselectComponent,
    DatepickerComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    ButtonComponent,
    DialogContainerComponent,
    NotificationModule,
    ListViewComponent,
    ListViewHeaderDirective,
    ListViewFooterDirective,
    ListViewItemDirective,
    GridHeaderActionsDirective,
    EmptyMessageComponent,
    TabLayoutComponent,
    IndicatorComponent,
    DrawerComponent,
    PanelbarComponent,
    PanelbarItemDirective,
    SplitButtonComponent,
    AutocompleteComponent,
    MaskedTextInputComponent,
    ComboboxComponent,
    ContentDisplayComponent,
    ConfirmDialogComponent,
    TextAreaComponent,
    ListComponent,
    NumericTextComponent,
    TreeViewComponent,
    MultiColumnCombobox,
    MultiColumnComboBoxTemplatesDirective,
    ExpansionPanelComponent,
    ExpansionPanelTitleDirective,
    TreeViewTemplateDirective,
    CarouselTemplateDirective,
    TextInputTemplateDirective,
    SearchTextComponent,
    DigitalClockComponent,
    CarouselComponent,
    DrawerTemplatesDirective,
    StackLayoutComponent,
    CardComponent,
    BadgeComponent,
    BadgeContainerComponent,
    NoticeCardComponent,
    BreadCrumbComponent,
    BreadCrumbTemplateDirective,
    ExtendedGridComponent,
    GridTemplatesDirective,
    LoaderComponent,
    TabStripComponent,
    TabStripTabComponent,
    TabStripTabTemplateDirective,
    LinkButtonComponent,
    GridLayoutComponent,
    GridLayoutItemDirective,
    TileLayoutComponent,
    TilelayoutItemComponent,
    TilelayoutItemBodyComponent,
    TilelayoutItemHeaderComponent,
    ErrorMessagesComponent,
    DropdownButtonComponent,
    WindowComponent,
    WindowTitlebarDirective,
    SidebarComponent,
    MatIconModule,
    SplitButtonIconTemplateDirective,
    WDatePipe, WPhonePipe,
    ButtonGroupComponent,
    RadioButtonComponent,
    FormMessageComponent,
    FormErrorComponent,
    GridViewLayoutComponent,
    GridViewExpansionPanelComponent,
    StepperLabelTemplateDirective,
    DropdownButtonTemplateDirective,
    ChartComponent,
    CategoryAxisComponent,
    CategoryAxisItemComponent,
    SeriesComponent,
    SeriesItemComponent,
    SeriesDefaultsComponent,
    ChartTitleComponent,
    ValueAxisItemComponent,
    SeriesItemTooltipComponent,
    ValueAxisComponent,
    SwitchComponent,
    ToolTipComponent,
    AutoFocusDirective
  ]
})
export class WUiAngularModule { }
