import { Component, ContentChild, EventEmitter, forwardRef, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFillMode, InputRounded, InputSize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';
import { TextInputTemplateDirective } from './directive/text-input-template.directive';

@Component({
  selector: 'williams-ui-platform-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent extends BaseAccessor {
  @ContentChild(
    forwardRef(() => TextInputTemplateDirective)
  )
  textInputTemplates: any;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() clearButton = true;
  @Input() showErrorIcon: boolean | 'initial' = 'initial';
  @Input() showCharacterCount:boolean = false;
  @Input() maxlength! :number ;
  @Input() rounded: InputRounded = 'none';
  @Input() size: InputSize = 'medium';
  @Input() fillMode: InputFillMode = 'solid';
  @Input() errorLabel!:string;
  @Output() blur	= new EventEmitter<any>();
  @Input() inlineStyle = {} ;
  @Input() elementId!: string;


  public charachtersCount:number = 0;
  public counter! :string;

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer)
    
  }

 override ngOnInit(): void {
  super.ngOnInit();
  this.counter = `${this.charachtersCount}/${this.maxlength}`;
 }

 onBlur(): void {
  this.blur.emit();
}

 resetCounter() {
  this.charachtersCount = 0;
  this.counter = `${this.charachtersCount}/${this.maxlength}`;
  }

  public onValueChange(ev: string): void {
    this.charachtersCount = ev.length;
    this.counter = `${this.charachtersCount}/${this.maxlength}`;
  }

}