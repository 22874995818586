import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() for:any;
  @Input() text = "";
  @Input() optional = false;
  @Input() showErrors = false;
  @Input() fontClass: string = "w-small-text-normal";
  constructor() { }

  ngOnInit(): void {
  }

}
